import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { myContext } from '../../../provider'
import { AllArticle } from '../../components/articles/index-articles'

const BlogPage = ({ data }) => {
  console.log(data)
  return (
    <myContext.Consumer>
    {context => (
        <>

          <div className="relative bg-indigo-800">
            <div className="absolute inset-0">
              <img
                className="w-full h-full object-cover"
                src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
                alt=""
              />
              <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
            </div>
            <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl sm:tracking-tight lg:text-6xl lg:tracking-tight text-center">
                Blog
              </h1>
              <p className="mt-6 text-xl text-indigo-100 text-center">
                Mattis amet hendrerit dolor, quisque lorem pharetra. Pellentesque lacus nisi urna, arcu sociis eu. Orci vel
                lectus nisl eget eget ut consectetur. Sit justo viverra non adipisicing elit distinctio.
              </p>
            </div>
          </div>
        
          <div className="container mx-auto grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 md:flex-row mt-6 md:space-x-12">
              <AllArticle data={data.gogobackend.all}/>
          </div>
        </>
    )}
    </myContext.Consumer>
  )
}

export const queryPrimary = graphql`
query {
  gogobackend {
      all: news_articles_content(
        status: "Published"
        limit: 20
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
      }
    }
  }
`

export default BlogPage